import React, {Fragment, useEffect, useState} from 'react'
import {Disclosure, RadioGroup, Tab, Menu, Transition} from '@headlessui/react'
import {
    BellIcon, StarIcon, FolderOpenIcon, PencilSquareIcon, CheckIcon, ChevronRightIcon, MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import {HeartIcon, MinusIcon, PlusIcon, XMarkIcon, Bars3Icon} from '@heroicons/react/24/outline'
import axios from "axios";
import {Link, useLocation, useParams} from "react-router-dom";
import {
    getLocalDateForEvent,
    mergeEventsCard,
    mergeVenues,
    thumbnailPad
} from "../../components/common/utilities";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";
import {Header} from "../../components/common/headerAndFooter";



function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function FindEventsStripped() {
    const [resultList, setResultList] = useState<any[]>([])
    const location = useLocation();
    const [searching, setSearching] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [attractionName, setAttractionName] = useState('');
    const [nextOffset, setNextOffset] = useState("");
    const [previousOffset, setPreviousOffset] = useState("");
    const [cancel, setCancel] = useState();
    const [mergeAllVenues, setMergeAllVenues] = useState(false);

    useEffect(() => {

        //getEvents("");
        getAttraction("");
        //getPhotos();

    }, []);

    let limit = 50;

    let params = useParams();

    function getAttraction(offset: string){
        let query = null;
        if(!query)
        {
            query = localStorage.getItem('attraction_name');
        }
        if(query){
            setSearching(true);
            setAttractionName(query);
            axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search.do?lang=is&event=true&q=`+query+`&offset=`+offset+`&limit=50`, {
            })
                .then(function (response) {
                    // handle success
                    //console.log(response);
                    let location = response.data;
                    console.log(location);
                    setSearching(false);
                    setResultList(location.collection);
                    setTotalResults(response.data.pagination.totalSize)
                    setNextOffset(response.data.pagination.nextOffset);
                    setPreviousOffset(response.data.pagination.previousOffset);
                    return location;

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    return error;
                })
                .finally(function () {
                    return 'done';
                });



        }
        else return;
    }






    let cancelToken;

    function searchVenue(e : any,next: string | number, previous: string){

        if (cancel)
        {
            // @ts-ignore
            cancel.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source()
        // @ts-ignore
        setCancel(cancelToken);

        let query = "";
        if(e.currentTarget)
        {
            query = e.currentTarget.value;
        }
        else
        {
            query = attractionName;
        }


        let params = {
            "ticket": localStorage.getItem('ticket'),
            "event": true,
            "q": query,
            "limit": limit,
        }
        if(next){
            // @ts-ignore
            params['offset'] = next;
        }
        if(previous){
            // @ts-ignore
            params['offset'] = previous;
        }
        if(query){
            setSearching(true);
            setAttractionName(query);
            localStorage.setItem('attraction_name', query);
            try
            {
                const results = axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search.do`, {
                    params: params,
                    cancelToken: cancelToken.token
                })
                    .then(function (response) {
                        // handle success
                        //console.log(response);
                        let location = response.data;
                        console.log(location);
                        setResultList(location.collection);
                        setTotalResults(response.data.pagination.totalSize)
                        setSearching(false);
                        setNextOffset(response.data.pagination.nextOffset);
                        setPreviousOffset(response.data.pagination.previousOffset);
                        return location;

                    })
                    .catch(function (error) {
                        // handle error
                        if (axios.isCancel(error)) {
                            console.log('Request canceled', error.message);
                        } else {
                            console.log(error);
                        }
                        return error;
                    })
                    .finally(function () {
                        return 'done';
                    });
            }
            catch (error)
            {

                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log(error);
                }
            }



        }
        else return;
    }

    const getNext = () => {
        searchVenue("", nextOffset, "");
    }
    const getPrevious = () => {
        searchVenue("", "", previousOffset);
    }

    const getFirst = () => {
        searchVenue("", 0, "");

    }

    const clearSearch = () => {
        setAttractionName('');
        localStorage.removeItem('attraction_name');
        setResultList([]);
        setTotalResults(0);
    }

    return (

        <div className="min-h-full">

            <div className="bg-white ">

                <div className="mx-auto mt-6 max-w-2xl leading-8 p-2">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg mb-4">

                            <p className="text-base font-semibold leading-7 text-indigo-600">Finndu viðburðinn</p>

                        </div>
                    </div>
                    <form>
                        <div
                            className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <MagnifyingGlassIcon
                                className="pointer-events-none absolute inset-y-0 left-1 h-full w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            <input
                                type="text"
                                name="venue_name"
                                id="venue_name"
                                className="block flex-1 border-0 bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 p-4 pl-8"
                                placeholder="Events"
                                defaultValue={attractionName ? attractionName : ''}
                                onKeyUp={(e) => searchVenue(e, "", "")}
                            />
                            {attractionName && <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={() => clearSearch()}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </button>}
                        </div>
                    </form>
                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {totalResults > 0 && totalResults} {attractionName && <>attractions matching
                        "</>}{attractionName}{attractionName && <>".</>}
                    </p>
                </div>

                <div className="mt-2">


                    <div className="mt-2">
                        <ul role="list" className="divide-y divide-gray-100">
                            {searching && <li className="p-4 text-gray-800 flex justify-center">
                                <div className={'text-pg_yellow'}><i
                                        className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                            </li>}

                            {!searching && resultList.map((venue) => (<li key={venue.id}
                                                                                    className="relative py-5 hover:bg-gray-50 cursor-pointer">
                                <a href={'/event/'+venue.id}><div className="px-4 sm:px-6 lg:px-8" data-button={'editEvent'}
                                     data-attractionid={venue.id} data-attractionname={venue.displayname}
                                     data-attractionimage={venue.bestImage}>
                                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                                        <div className="flex gap-x-4">
                                            {venue.imageurl && thumbnailPad(venue.imageurl)}
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">

                                                                        <span
                                                                            className="absolute inset-x-0 -top-px bottom-0"
                                                                            data-button={'editEvent'}
                                                                            data-attractionid={venue.id}
                                                                            data-attractionname={venue.displayname}
                                                                            data-unique={true} data-remove={false}
                                                                            data-attractionimage={venue.bestImage}/>
                                                    {venue.displayname}

                                                </p>
                                                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                    {venue.majortype} {venue.country}
                                                </p>
                                                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                    {venue.venuename}
                                                </p>
                                                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                    {venue.when && getLocalDateForEvent(venue, venue.when, null)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-4">
                                            <div
                                                className="hidden sm:flex sm:flex-col sm:items-end">
                                                <p className="text-sm leading-6 text-gray-900">{venue.majorCategory}</p>
                                            </div>
                                            <ChevronRightIcon
                                                className="h-5 w-5 flex-none text-gray-400"
                                                aria-hidden="true"/>
                                        </div>
                                    </div>
                                </div></a>
                            </li>))}

                        </ul>
                        {resultList && resultList.length > 0 && <nav
                                className="flex items-center justify-between border-t border-gray-200 px-4 py-2 sm:px-0  mt-2 mb-2 mr-4 ml-4"
                                id={'topnav'}>
                            <div className="-mt-px flex w-0 flex-1">
                                <div
                                        onClick={() => getFirst()}
                                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-blue-300 hover:text-gray-700"
                                >
                                    <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-gray-400"
                                                           aria-hidden="true"/>

                                </div>
                            </div>
                            <div className="flex">

                                <div
                                        onClick={() => getPrevious()}
                                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400"
                                                       aria-hidden="true"/>
                                </div>
                                <div
                                        onClick={() => getNext()}
                                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"/>
                                </div>
                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">

                            </div>
                        </nav>}

                    </div>
                </div>

            </div>

        </div>)
}
