import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'

import {Link, useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {
    adminEventCard,
    copyTextToClipboard,
    getAttribute,
    getDate,
    getLocalDate,
    getSite,
    MetaTags,
    setUniqueEventAttribute,
    smallEventCard, toastSuccess
} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ToastContainer} from "react-toastify";

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function Toplist() {
    const navigate = useNavigate();
    const logout = () => {
        window.location.href = '/logout';
    }

    let params = useParams();
    const venueID = params.venueID;
    const loaderdata = useLoaderData() as any;
    //console.log(loaderdata);
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];

    const [events, setEvents] = useState([]);
    const eventsDescending = [...events].sort((a:any,b:any) => b.count - a.count);
    const [ads, setAds] = useState([]);
    const adsDescending = [...ads].sort((a:any, b:any) => b.count - a.count);
    const [edp, setEDP] = useState([]);
    const edpsDescending = [...edp].sort((a:any, b:any) => b.count - a.count);
    const [showEDPs, setShowEDPs] = useState(true);
    const [showAds, setShowAds] = useState(false);
    const [eventOffset, setEventOffset] = useState(0);
    const [eventLimit, setEventLimit] = useState(10);
    const [inQueue, setInQueue] = useState(0);
    const [inProgress, setInProgress] = useState(0);
    const [inPipeline, setInPipeline] = useState(0);
    const [limit, setLimit] = useState(100);
    const [NLPUrl, setNLPUrl] = useState([]);
    const [NLPData, setNLPData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addingEvent, setAddingEvent] = useState(false)
    const [gettingEvents, setGettingEvents] = useState(false)
    const [importString, setImportString] = useState('');
    const [empty, setEmpty] = useState(false);
    const [days, setDays] = useState(1);
    const [totalAds, setTotalAds] = useState(0);
    const [totalEDP, setTotalEDP] = useState(0);

    var date = new Date();
    let ctacount = 0;
    var edpcount = 0;
    var adcount = 0;

    function isUrlValid(url:string) {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    }
    const getEvents = (numberofdays:number) => {
        setGettingEvents(true)
        setInQueue(0);
        setInProgress(0);
        if(!numberofdays)
        {
            numberofdays = days;
        }


        var queue = 0;
        var progress = 0;
        var pipeline = 0;



        axios.post(`${process.env.REACT_APP_API_URL}/p/manage/event/statistics.do`, {
            ticket: localStorage.getItem('ticket'),
            days: numberofdays,
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded',
                       }
                   }).then(function (response) {
            // handle success
            //console.log("events", response);

            if(response.data.list.length === 0)
            {
                setEmpty(true);
            }

            setEvents(response.data.list);
            //console.log(response.data.list);
            setGettingEvents(false)
            let theads:any = [];
            let theedps:any = [];
            response.data.list.map((event:any) => {
                if(event.guid.startsWith('ad'))
                {
                    theads.push(event);
                    adcount = adcount + event.count;
                    //console.log('adcount', adcount);
                }
                else if(event.guid.startsWith('edp'))
                {
                    theedps.push(event);
                    edpcount = edpcount + event.count;
                    //console.log('edpcount', edpcount);
                }
            });
            setAds(theads);
            setEDP(theedps);
            setTotalAds(adcount);
            setTotalEDP(edpcount);



        }).catch(function (error) {
            // handle error
            console.log(error);
            setGettingEvents(false)
        });
    }

    const pollEvents = (url:any) => {
        setCount(count + 1);
        setTimeout(() => getEvents(days), 30000)
    }

    const [count, setCount] = useState(0);


    useEffect(() => {

        getEvents(days);

    }, []);

    // EDIT EVENT DIALOG

    const [open, setOpen] = useState(false);

    const toggleDisplay = () => {
        if(showEDPs)
        {
            setShowEDPs(false);
            setShowAds(true);
        }
        else {
            setShowAds(false);
            setShowEDPs(true);
        }
    }

    const displayDays = (numberofdays:any) => {
        setDays(numberofdays);
        setEvents([]);
        getEvents(numberofdays);
    }

    type SchemaEvent = {
        [key: string]: any; // ?? variable key
        name: string;
    };
    type SchemaLocation = {
        [key: string]: any; // ?? variable key
        name: string;
    };

    const [eventData, setEventData] = useState<SchemaEvent>();
    const [venueData, setVenueData] = useState<SchemaLocation>();


    const createEvent = (e:any) => {
        e.preventDefault();
        console.log('posting ', eventData);
        axios.post(`${process.env.REACT_APP_API_URL}/go/api/event/create.do`, eventData, {}).then(function (response) {
            // handle success
            console.log(response);
            //setRooms(response.data.collection);
            //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

        });
    }



    const viewEvent = (event:any) => {
          if(event.guid.startsWith("edp:"))
          {
              let theguid = event.guid.replace('edp:', '');
                navigate(`/event/${theguid}`);
          }
          else if(event.guid.startsWith("ad:"))
          {
              let theguid = event.guid.replace('ad:', '');
              navigate(`/event/${theguid}`);
          }
    }





    return (<>
        {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
        <div className="min-h-full dark:bg-white bg-white">
            <MetaTags title={'Top list'} description={"What's hot?"} />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={setOpen}>
                    <div className="fixed inset-0"/>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-2">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div
                                            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll overflow-x-hidden py-1">
                                                <div className="px-2 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title
                                                            className="text-base font-semibold leading-6 text-gray-900">
                                                            Action Panel
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                <span className="absolute -inset-2.5"/>
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative mt-6 flex-1 px-4 sm:px-6">

                                                    {eventData && !eventData.name && <div className={'px-4 py-5 sm:p-6 text-green-800 justify-items-center'}><div><i className="fa-duotone fa-circle-notch fa-spin"></i></div></div> }

                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="lg:pl-72">

                <main className="md:grid gap-4 grid-cols-1">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="-ml-4 -mt-2 pb-2 flex flex-wrap items-center justify-between sm:flex-nowrap sticky top-0 bg-white z-30">
                            <div className="ml-4 mt-2">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">Period</h3>

                                <Menu as="div" className="relative text-left">
                                    <div>
                                        <MenuButton
                                            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                            {days === 1 && <span>1 Day</span>}{days === 7 && <span>1 Week</span>}{days === 30 && <span>1 Month</span>}{days === 365 && <span>1 Month</span>}
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400"
                                                             aria-hidden="true"/>
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => displayDays(1)}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    Day
                                                </a>)}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => displayDays(7)}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    Week
                                                </a>)}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => displayDays(30)}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    Month
                                                </a>)}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => displayDays(365)}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    Year
                                                </a>)}
                                            </MenuItem>

                                        </div>
                                    </MenuItems>
                                </Menu>
                                <div className={'text-gray-900 text-sm'}>{totalAds} Ads {totalEDP} EDPs</div>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0 pt-2">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">Showing</h3>

                                <Menu as="div" className="relative text-left">
                                    <div>
                                        <MenuButton
                                            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                            {showEDPs && <span>EDPs</span>}{showAds && <span>Ads</span>}
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400"
                                                             aria-hidden="true"/>
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => toggleDisplay()}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    EDPs
                                                </a>)}
                                            </MenuItem>
                                            <MenuItem>
                                                {({focus}) => (<a
                                                    href="#" onClick={() => toggleDisplay()}
                                                    className={classNames(focus ? 'bg-gray-100' : 'bg-white', ' text-gray-900 block px-4 py-2 text-sm')}
                                                >
                                                    Ads
                                                </a>)}
                                            </MenuItem>

                                        </div>
                                    </MenuItems>
                                </Menu>

                            </div>
                        </div>

                        <div>
                            <div className="px-4 sm:px-6 lg:px-2">
                                {gettingEvents && <div className="p-4 text-gray-800 flex justify-center w-full">
                                    <div className={'text-pg_yellow'}><i
                                            className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                                </div>}
                                {empty && <div className="p-4 text-gray-800 flex justify-center w-full">
                                     <div className={'text-pg_yellow'}><i className="fa-duotone fa-exclamation"></i> No
                                         Events found
                                     </div>
                                 </div>
                                }
                                <div className="-mx-4 mt-2 sm:-mx-0">

                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-white">
                                        <tr>
                                            <th scope="col"
                                                className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                            </th>
                                            <th scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                <div
                                                    className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200"/>
                                                <div
                                                    className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200"/>
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>

                                        {showEDPs && edpsDescending && edpsDescending.map((event:any, index) => (
                                            <tr key={getAttribute(event, "guid")}
                                                className={'even:bg-gray-50 odd:bg-white cursor-pointer'} onClick={() => viewEvent(event)}>
                                                <td className="relative w-1/4 py-1 pr-1 text-sm font-medium text-gray-900">
                                                    <img className={'w-11/12'} src={event.image}/>
                                                </td>
                                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-small text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                                    <div>
                                                    <div className={'font-medium'}>{event.title}</div>
                                                    <div>{event.count}</div>
                                                    </div>

                                                    <div
                                                        className="absolute bottom-0 right-full h-px w-screen bg-gray-100"/>
                                                    <div
                                                        className="absolute bottom-0 left-0 h-px w-screen bg-gray-100"/>
                                                </td>
                                                <td className="relative py-4 pl-3 text-right text-sm font-medium">

                                                </td>
                                            </tr>))}

                                        {showAds && adsDescending && adsDescending.map((event:any, index) => (
                                            <tr key={getAttribute(event, "guid")}
                                                className={'even:bg-gray-50 odd:bg-white cursor-pointer'} onClick={() => viewEvent(event)}>
                                                <td className="relative w-1/4 py-1 pr-1 text-sm font-medium text-gray-900">
                                                    <img className={'w-11/12'} src={event.image} />
                                                </td>
                                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-small text-gray-900 sm:w-auto sm:max-w-none sm:pl-0" >
                                                    <div>
                                                        <div className={'font-medium'}>{event.title}</div>
                                                        <div>{event.count}</div>
                                                    </div>

                                                    <div
                                                        className="absolute bottom-0 right-full h-px w-screen bg-gray-100"/>
                                                    <div
                                                        className="absolute bottom-0 left-0 h-px w-screen bg-gray-100"/>
                                                </td>
                                                <td className="relative py-4 pl-3 text-right text-sm font-medium">

                                                </td>
                                            </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        </div>
        <ToastContainer autoClose={800} theme={'dark'} />

    </>)
}
