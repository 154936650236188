import {EnvelopeIcon, PhoneIcon, FolderOpenIcon, PencilSquareIcon} from '@heroicons/react/20/solid'
import React, {useEffect, useState} from "react";
import {useLoaderData} from "react-router-dom";
import {getAttribute} from "../../components/common/utilities";
import axios from "axios";

export default function Facelist() {
    const [propertyList, setPropertyList] = useState<any[]>([])
    //setPropertyList(data);
    const [faces, setFaces] = useState<any[]>([]);
    const [nextOffset, setNextOffset] = useState("");
    const [previousOffset, setPreviousOffset] = useState("");

    function getBestImage(property: any)
    {
        let attr = property.attributes;
        let bestPicture = '';
        if (attr && attr.length > 0)
        {
            for (let i = 0; i < attr.length; i++)
            {
                if (attr[i].key === 'bestPicture')
                {
                    bestPicture = attr[i].value;
                    break
                }
            }
        }
        return bestPicture;
    }

    function getPicture(property: any)
    {
        let attr = property.attributes;
        let bestPicture = '';
        if (attr && attr.length > 0)
        {
            for (let i = 0; i < attr.length; i++)
            {
                if (attr[i].key === 'picture')
                {
                    bestPicture = attr[i].value;
                    break
                }
            }
        }
        return bestPicture;
    }

    function getArtistOrVenue(property: any)
    {
        let attr = property.attributes;
        let bestPicture = '';
        if (attr && attr.length > 0)
        {
            for (let i = 0; i < attr.length; i++)
            {
                if (attr[i].key === 'promo.custom.artistid')
                {
                    bestPicture = attr[i].value;
                    break
                }
                if (attr[i].key === 'promo.custom.venueid')
                {
                    bestPicture = attr[i].value;
                    break
                }
            }
        }
        return bestPicture;
    }

    const getFaces = nextOffset => {
        window.scrollTo(0, 0);

        axios.post(`${process.env.REACT_APP_API_URL}/p/api/gogo/facelist.do`, {
            ticket: localStorage.getItem('ticket'),
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log("events", response);
            setFaces(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const goToProperty = (roomguid: any) => {
        window.location.href = '/property/' + roomguid;
    }
    useEffect(() => {

        getFaces("");
        //getPhotos();

    }, []);

    return (<div className={'p-2'}>
        <div role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <div key={'addProperty'} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">Stofna nýjan</h3>

                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">Fyrir auglýsanda eða viðburðahaldara.</p>
                    </div>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1" data-button={'createArtist'}>

                            <div
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                                <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                ARTIST
                            </div>
                        </div>
                        <div className="flex w-0 flex-1">

                            <div
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                                <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                VENUE
                            </div>
                        </div>
                        <div className="flex w-0 flex-1">

                            <div
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                                <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                MEDIA
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {faces && faces.map((face) => <div key={face.guid} className="col-span-1overflow-hidden bg-white shadow sm:rounded-lg" data-button={'face'} data-guid={face.guid}>
                <div className="flex w-full items-center justify-between space-x-6 p-6">

                    <div className="flex-1 truncate" aria-hidden="true">

                        <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900" >{face.name}</h3>
                            <button className={'text-xs text-gray-500 rounded border p-1'} data-button={'face'} data-guid={face.guid}>Load</button>
                        </div>
                        <div><span
                            className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {face.mask}
                </span></div>

                    </div>
                    <div>
                        {getBestImage(face) && (<div><img className="h-16 w-16 flex-shrink-0 rounded bg-gray-300"
                                                          src={'https://promogogo.com/cdn-cgi/image/width=230,height=230,fit=pad/' + getBestImage(face)}
                                                          alt=""/></div>)}
                        {!getBestImage(face) && getPicture(face) && (
                            <div><img className="h-16 w-16 flex-shrink-0 rounded bg-gray-300"
                                      src={'https://promogogo.com/cdn-cgi/image/width=230,height=230,fit=pad/' + getPicture(face)}
                                      alt=""/></div>)}
                        {!getBestImage(face) && !getPicture(face) && (
                            <div><img className="h-16 w-16 flex-shrink-0 rounded bg-white"
                                      src={'https://media.promogogo.com/ojqwiyls/radar-f15ed2e7-915a-4a7b-a098-ae3236576a0a-promo_icon_400x400px_circle.png'}
                                      alt=""/></div>)}
                    </div>

                </div>
            </div>)}
        </div>
    </div>)
}
