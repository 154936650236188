import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'

import Propertylist from './facelist.component'
import Facelist from "./facelist.component";
import MainNavigation from "../../components/common/navigation.component";
import SidebarNavigation from "../../components/common/sidebarnavigation.component";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import {Truncate} from '@re-dev/react-truncate'
import axios from "axios";
import {
    addEventAttribute,
    adminEventCard,
    audienceButtons,
    copyTextToClipboard,
    MetaTags,
    setDefaultPerformer,
    setUniqueEventAttribute,
    sidebarArtistCard,
    smallEventCard,
    smallSchemaArtistCard,
    toastSuccess, useLanguage
} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";
import {ToastContainer} from "react-toastify";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import {LocalizationProvider} from "@mui/x-date-pickers";

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
    const navigate = useNavigate();
    const logout = () => {
        window.location.href = '/logout';
    }

    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;
    //console.log(data);

    const [events, setEvents] = useState([]);
    const [artistEvents, setArtistEvents] = useState([]);
    const [edp, setEDP] = useState([]);
    const [eventOffset, setEventOffset] = useState(0);
    const [eventLimit, setEventLimit] = useState(10);
    const [nextOffset, setNextOffset] = useState("");
    const [previousOffset, setPreviousOffset] = useState("");
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const [majortype, setMajorType] = useState("");
    const [audience, setAudience] = useState("");
    const [startDate, setStartDate] = useState("");
    const [country, setCountry] = useState("IS");
    const language = useLanguage();

    type SchemaArtist = {
        [key: string]: any; // ?? variable key
        name: string;
    };
    const [artistData, setArtistData] = useState<SchemaArtist>();

    const today = new Date();
    const dateString = today.toISOString().split('T')[0];
    const getEvents = (nextOffset:any, majorcategory:any, filteraudience:any, startondate:any) => {
        window.scrollTo(0, 0);
        setEvents([]);
        let eventCountry = localStorage.getItem('country');
        if(!eventCountry)
        {
            eventCountry = country;
        }
        else {
            setCountry(eventCountry);
        }



        axios.get(`${process.env.REACT_APP_API_URL}/p/events/artistsforpartners.do`, {
            params: {
                limit: 96,
                country: eventCountry,
                offset: nextOffset,
                lang: language,
                tiny: true,
                guid: data.guid,
                admin: true,
                majorcategory: majorcategory,
                audience: filteraudience,
                allattributes: true,
                startdate: startondate,
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            setEvents(response.data.collection);
            setNextOffset(response.data.pagination.nextOffset);
            setPreviousOffset(response.data.pagination.previousOffset);
            setEventOffset(eventOffset + eventLimit);
        });
    }

    const selectCountry = (e:any) => {
        e.preventDefault();
        let type = e.target.value;
        localStorage.setItem('country', type);
        setCountry(type);
        getEvents("", type, audience, startDate);
    }

    const showByType = (e:any) => {
        e.preventDefault();
        let type = e.target.value;
        setMajorType(type);
        getEvents("", type, audience, startDate);
    }

    const showByAudience = (e:any) => {
        e.preventDefault();
        let type = e.target.value;
        setAudience(type);
        getEvents("", majortype, type, startDate);
    }

    const changeStart = (e) => {
        console.log(e._d);
        var d = new Date(e._d);

        console.log(d.toISOString());
        setEvents([]);
        setStartDate(d.toISOString());
        getEvents("", majortype, audience, d.toISOString())
    }

    const getArtistEvents = (nextOffset, artistid) => {
        //window.scrollTo(0, 0);
        setArtistEvents([]);


        axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
            params: {
                limit: 48, artist: artistid, offset: nextOffset, lang: "is", light: true,
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            setArtistEvents(response.data.collection);

        });
    }

    const getMoreEvents = () => {
        getEvents(eventOffset, majortype, audience, startDate);
    }

    useEffect(() => {

        //getEvents("", majortype, audience, startDate);
        //getPhotos();

    }, []);

    function clickedEvent(e)
    {
        console.log("clicked", e.target);

        let button = e.target.dataset.button;
        if (button === 'setAd')
        {
            console.log('setAd');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key, value, unique, remove, eventid);

            toastSuccess('Event updated');

        }
        else if (button === 'setPrivate')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key, value, unique, remove, eventid);

            toastSuccess('Event set to private');

        }
        else if (button === 'viewEvent')
        {
            let eventid = e.target.dataset.eventid;
            /*
                        viewArtist(null,eventid);
            */
        }
        else if (button === 'setAttractionType')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key, value, unique, remove, eventid);

            toastSuccess('Attraction Type set to ' + value);

        }

        else if (button === 'addAttribute')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = false;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            addEventAttribute(key, value, unique, eventid);

            toastSuccess('Attribute ' + key + ' set to ' + value);

        }

        else if (button === 'manageAudience')
        {
            console.log('managing audience');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = false;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            addEventAttribute('pgg.audience.' + key, 'pgg.audience.' + value, remove, eventid);

            toastSuccess('Audience set ' + key + ' Remove: ' + remove);

        }

        /*let venueid = e.target.dataset.venueid;
        if (venueid !== undefined)
        {
            console.log(`Event bubbled up to div from `, venueid);
            //console.log(eventData);
            let venuename = e.target.dataset.venuename;
            let venueimage = e.target.dataset.venueimage;

            var locationData = eventData.location;
            locationData.name = venuename;
            locationData.image = venueimage;
            locationData['@id'] = venueid;

            setVenueData(locationData);

            setEventData({
                             ...eventData, location: {
                    locationData
                }

                         })
            console.log(eventData);

        }*/


    }

    const viewArtist = (artist) => {
        setArtistData(artist);
        setOpen(true);
        getArtistEvents("", artist['@id']);

    }


    return (<>
            <MetaTags
                title='Viðburðir á Íslandi'
                description='Auðveldaðu menningarumfjöllunina'
                image='https://promogogo.com/cdn-cgi/image/fit=contain,width=768/https://media.promogogo.com/ymsirvidburdir/2024-06-10/3666078aaa094262b626960bf705498b.png'
                name='Team Mobilitus'
            />
            <div className="min-h-full dark:bg-white bg-white">
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-40" onClose={setOpen}>
                        <div className="fixed inset-0"/>

                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-2">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                            <div
                                                className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                                <div
                                                    className="flex min-h-0 flex-1 flex-col overflow-y-scroll overflow-x-hidden py-1">

                                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                        {artistData && artistData.name &&
                                                         <div className="px-4 py-5 sm:p-6">
                                                             {sidebarArtistCard(artistData)}
                                                         </div>}
                                                        {!artistData &&
                                                         <div className="p-4 text-gray-800 flex justify-center">
                                                             <div className={'text-pg_yellow'}><i
                                                                     className="fa-2x fa-duotone fa-loader fa-spin"></i>
                                                             </div>
                                                         </div>}

                                                        {/*{artistData && <>
														<button onClick={() => setDefaultPerformer(artistData)} className={'bg-green-500 text-white p-2 m-2'}>Set as default performer</button>
													</>}*/}
                                                        <div className={'p-4'} onClick={(event) => clickedEvent(event)}>
                                                            {artistData && audienceButtons(artistData)}
                                                        </div>
                                                        <h5 className="text-md font-semibold text-gray-900 mt-4">Events</h5>
                                                        {artistEvents.length > 0 && artistEvents.map((event) => (
                                                            <div className="px-4 pt-2 sm:p-2"
                                                                 key={'artistevent' + event['@id']}>
                                                                {smallEventCard(event)}
                                                            </div>))}

                                                    </div>
                                                </div>
                                                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
                <SidebarNavigation/>
                <div className="lg:pl-72">
                    <div className="mx-auto max-w-7xl px-6 py-8 sm:py-32 lg:px-8">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Hverjir eru með eitthvað
                            <br/>
                            næstu vikuna.
                        </h2>
                        <div>
                            <div className={'text-gray-500 text-xs'}>In</div>
                            <div>
                                <select
                                    onChange={(e) => selectCountry(e)}
                                    value={country}
                                    className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-3">
                                    <option value="">Select</option>
                                    <option value={'IS'}>Iceland</option>
                                    <option value={'NO'}>Norway</option>
                                    <option value={'DK'}>Denmark</option>
                                    <option value={'SE'}>Sweden</option>
                                    <option value={'UK'}>United Kingdom</option>
                                    <option value={'US'}>United States</option>
                                    <option value={'FO'}>Faeroe Islands</option>
                                </select>

                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                    <DatePicker
                                        label="Starting date"
                                        name={'date'}
                                        defaultValue={moment(dateString)}
                                        onChange={changeStart}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="mt-3 flex items-top gap-x-6">

                            <div>
                                <div className={'text-gray-500 text-xs'}>Type</div>
                                <div>
                                    <select
                                        onChange={(e) => showByType(e)}
                                        value={majortype}
                                        className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <option value="">All</option>
                                        <option value={'arts'}>Arts</option>
                                        <option value={'burlesque'}>Burlesque/Drag</option>
                                        <option value={'community'}>Community</option>
                                        <option value={'exhibition'}>Exhibitions</option>
                                        <option value={'festival'}>Festivals</option>
                                        <option value={'music'}>Music</option>
                                        <option value={'sports'}>Sports</option>
                                        <option value={'standup'}>Standup</option>
                                        <option value={'theatre'}>Theatre</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div className={'text-gray-500 text-xs'}>Audience:</div>
                                <div>
                                    <select
                                        onChange={(e) => showByAudience(e)}
                                        value={audience}
                                        className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <option value="">All</option>
                                        <option value={'kids'}>Kids</option>
                                        <option value={'elderly'}>Elderly</option>
                                        <option value={'adults'}>Adults</option>
                                        <option value={'touristfriendly'}>Tourist Friendly</option>
                                        <option value={'hottickets'}>Hot Tickets</option>
                                        <option value={'couples'}>Couples</option>
                                        <option value={'singles'}>Singles</option>
                                        <option value={'lgbtq'}>LGBTQ</option>
                                        <option value={'families'}>Families</option>
                                        <option value={'Parents'}>Parents</option>
                                        <option value={'innovation'}>Innovation</option>
                                        <option value={'foodies'}>Foodies</option>
                                        <option value={'students'}>Students</option>
                                        <option value={'musiclovers'}>Music Lovers</option>
                                    </select>

                                </div>

                            </div>

                        </div>
                    </div>
                    <main className="p-1">
                        <nav
                            className="sticky bg-white z-30 flex items-center justify-between px-4 sm:px-0  mt-2 mb-2"
                            style={{top: "60px"}}>
                            <div className="-mt-px flex w-0 flex-1">
                                <div
                                    onClick={() => getEvents(0, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >
                                    <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-green-800"
                                                           aria-hidden="true"/>

                                </div>
                            </div>
                            <div className="-mt-px md:flex">
                                <div
                                    onClick={() => getEvents(previousOffset, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >
                                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-green-800" aria-hidden="true"/>

                                </div>
                                <div
                                    onClick={() => getEvents(nextOffset, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pl-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >

                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-green-800" aria-hidden="true"/>
                                </div>

                            </div>
                            <div className="-mt-px flex w-0 flex-1 ">
                            </div>
                        </nav>
                        <div className={'text-gray-500 text-sm'}>Smelltu á texta til að afrita</div>

                        <div role="list"
                             className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                            {!filter && events.map((artist) => (<div key={artist['@id']}>
                                <div onClick={(e) => viewArtist(artist)}>
                                    <img
                                        src={'https://promogogo.com/cdn-cgi/image/width=400,height=300,fit=cover/' + artist.image}
                                        alt=""
                                        className="hover:opacity-75"/>
                                </div>
                                <h3 className={'text-md font-semibold text-gray-900 mt-4'}
                                    onClick={(e) => viewArtist(artist)}>{artist.name}</h3>
                                {artist.description && <div className={'text-sm text-gray-500 mt-2'}
                                                            onClick={(e) => copyTextToClipboard(artist.description.replace(/<[^>]*>?/gm, ' '))}
                                >
                                    <Truncate
                                            lines={3}
                                            ellipsis={<span>
          ...
        </span>}

                                    >
                                        {artist.description.replace(/<[^>]*>?/gm, '')}
                                    </Truncate>

                                </div>}
                            </div>))}
                            {filter && filter === 'nodescription' && events.map((artist) => (<>
                                {!artist.description && <div key={artist['@id']}>
                                    <div onClick={(e) => viewArtist(artist)}>
                                        <img
                                                src={'https://promogogo.com/cdn-cgi/image/width=400,height=300,fit=cover/' + artist.image}
                                                alt=""
                                                className="hover:opacity-75"/>
                                    </div>
                                    <h3 className={'text-md font-semibold text-gray-900 mt-4'}>{artist.name}</h3>
                                    {artist.description && <div className={'text-sm text-gray-500 mt-2'}
                                                                onClick={(e) => copyTextToClipboard(artist.description.replace(/<[^>]*>?/gm, ''))}
                                                                dangerouslySetInnerHTML={{__html: artist.description}}></div>}
                                </div>}
                            </>))}

                        </div>
                        {events && events.length === 0 && <div className="p-4 text-gray-800 flex justify-center">
                            <div className={'text-pg_yellow'}><i
                                    className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                        </div>}
                        <nav className="bg-white z-30 flex items-center justify-between px-4 sm:px-0  mt-2 mb-2"
                             style={{top: "60px"}}>
                            <div className="-mt-px flex w-0 flex-1">
                                <div
                                    onClick={() => getEvents(0, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >
                                    <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-green-800"
                                                           aria-hidden="true"/>

                                </div>
                            </div>
                            <div className="-mt-px md:flex">
                                <div
                                    onClick={() => getEvents(previousOffset, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >
                                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-green-800" aria-hidden="true"/>

                                </div>
                                <div
                                    onClick={() => getEvents(nextOffset, majortype, audience, startDate)}
                                    className="cursor-pointer inline-flex items-center border-b-2 border-transparent pl-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                                >

                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-green-800" aria-hidden="true"/>
                                </div>

                            </div>
                            <div className="-mt-px flex w-0 flex-1 ">

                            </div>
                        </nav>
                    </main>
                </div>
            </div>
            <ToastContainer autoClose={800} theme={'dark'}/>


        </>)
}
