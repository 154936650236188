import { useRouteError } from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";

export default function LoginPage() {

    const [statusMessage, setStatusMessage] = useState(null);
    const [detailStatusMessage, setDetailStatusMessage] = useState(null);

    const stopBouncing = () => {
        const el = document.getElementById("promo-icon");
        if (el) {
            el.classList.remove("animate-bounce");
        }
    }

    useEffect(() => {
        setTimeout(() => stopBouncing(), 3500)
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        setStatusMessage(null);
        setDetailStatusMessage(null)
        axios.post(`${process.env.REACT_APP_API_URL}/login/app/login.do`, document.querySelector('#loginform'), {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: false
        })
                .then(function (response) {
                    console.log(response);
                    if (response.data.ticket)
                    {
                        localStorage.setItem('ticket', response.data.ticket);
                        localStorage.setItem('username', response.data.name);
                        window.location.href = '/dashboard';
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setStatusMessage(error.message)
                    setDetailStatusMessage(error.response.data.error)
                });
    };

    return (<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="flex justify-center">
                    <img id="promo-icon"
                         className="h-16 w-auto animate-bounce"
                         src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                         alt="Promogogo"/>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm ">

                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Sign in
                        to your account</h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded p-3">
                    <form id="loginform" className="space-y-6" onSubmit={submitForm}>
                        <input type="hidden" name="instance" value="promogogo"/>
                        <input type="hidden" name="appName" value="backstage"/>

                        <div>
                            <label htmlFor="loginName" className="block text-sm font-medium leading-6 text-gray-900">Email
                                address</label>
                            <div className="mt-2">
                                <input id="loginName" name="loginName" type="email" autoComplete="email" required
                                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password"
                                       className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                <div className="text-sm">
                                    <a href="/sendresetlink"
                                       className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot
                                        password?</a>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input id="password" name="password" type="password" autoComplete="current-password"
                                       required
                                       className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>

                        <div>
                            <button type="submit"
                                    className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign
                                in
                            </button>
                        </div>
                        <div className="text-center text-red-500 p-1">
                            {statusMessage && <span className="text-center text-red-500 p-1">{statusMessage}</span>}
                            {detailStatusMessage &&
                             <span className="text-center text-red-500 p-1"><br/>{detailStatusMessage}</span>}
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member? <a href="/createuser"
                                         className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Create
                        your account</a>
                    </p>
                </div>
            </div>);
}
