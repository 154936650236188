import {useParams, useRouteError} from "react-router-dom";
import React, {useState} from "react";
import axios from "axios";

export default function NewPasswordPage() {

    const [statusMessage, setStatusMessage] = useState(null);
    const [detailStatusMessage, setDetailStatusMessage] = useState(null);
    let params = useParams();


    const submitForm = (e) => {
        e.preventDefault();
        setStatusMessage(null);
        setDetailStatusMessage(null)
        axios.post(`${process.env.REACT_APP_API_URL}/keepsapi/api/resetpassword.do`, {
            instance: 'keeps', token: params.token, password: e.target.password.value
        },{
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }})
                .then(function (response) {
                    console.log(response);
                    window.location.href = '/login';
                })
                .catch(function (error) {
                    console.log(error);
                    setStatusMessage(error.message)
                    setDetailStatusMessage(error.response.data.error)
                });
    };

    return (<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-10 w-auto" src="/logo-dark-full.png" alt="Keeps"/>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Set New Password</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form id="createform" className="space-y-6" onSubmit={submitForm}>
                <input type="hidden" name="instance" value="keeps"/>
                <input type="hidden" name="appName" value="keeps"/>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password"
                               className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                    </div>
                    <div className="mt-2">
                        <input id="password" name="password" type="password" autoComplete="new-password"
                               required
                               className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                </div>

                <div>
                    <button type="submit"
                            className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Reset
                        password
                    </button>
                </div>
                <div className="text-center text-red-500 p-1">
                    {statusMessage && <span className="text-center text-red-500 p-1">{statusMessage}</span>}
                    {detailStatusMessage && <span className="text-center text-red-500 p-1"><br/>{detailStatusMessage}</span>}
                </div>

            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
                Didn't mean to reset your password? <a href="/login"
                                                       className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Log
                in</a>
            </p>
        </div>
    </div>);
}
