import {useLoaderData, useParams, useRouteError} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";

export default function VerifyEmailPage() {

    let params = useParams();
    console.log(params);

    const stopBouncing = () => {
        const el = document.getElementById("promo-icon");
        if (el) {
            el.classList.remove("animate-bounce");
        }
    }

    const [statusMessage, setStatusMessage] = useState(null);
    const [detailStatusMessage, setDetailStatusMessage] = useState(null);
    const [verified, setVerified] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        setTimeout(() => stopBouncing(), 3500)
    }, []);

    const verify = () => {
        return axios.post(`${process.env.REACT_APP_API_URL}/p/user/verifyemail.do`, {
            instance: 'promogogo', ticket: localStorage.getItem('ticket'), token: params.token,
        }, {
                              headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded'
                              }
                          }).then(function (response) {
            // handle success
            console.log(response);
            setVerified(true);
            window.location.href = '/dashboard';
            return response.data;

        }).catch(function (error) {
            console.log(error);
            setVerified(false)
            setStatusMessage(error.message)
            setDetailStatusMessage(error.response.data.error)
            return error.response;
        });
    }

    const submitForm = (e) => {
        e.preventDefault();
        setStatusMessage(null);
        setDetailStatusMessage(null)
        axios.post(`${process.env.REACT_APP_API_URL}/login/app/login.do`, document.querySelector('#loginform'), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
                .then(function (response) {
                    console.log(response);
                    if (response.data.ticket)
                    {
                        localStorage.setItem('ticket', response.data.ticket);
                        localStorage.setItem('username', response.data.name);
                        setShowLogin(false)
                        verify().then(function (response) {
                            window.location.href = '/dashboard';
                        }).catch(function (error) {
                            console.log('catch', error);
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setStatusMessage(error.message)
                    setDetailStatusMessage(error.response.data.error)
                });
    };

    useEffect(() => {
        if (localStorage.getItem('ticket'))
        {
            verify().then(function (response) {
                console.log('then', response);

            }).catch(function (error) {
                console.log('catch', error);
            })
        }
        else
        {
            setVerified(false)
            setShowLogin(true)
        }


    }, []);

    return (<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex justify-center">
            <img id="promo-icon"
                 className="h-16 w-auto animate-bounce"
                 src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                 alt="Promogogo"/>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Verifying email</h2>
        </div>

    </div>);
}
