import {useLoaderData, useParams, useRouteError} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getAttribute} from "../../components/common/utilities";
import {Tab} from "@headlessui/react";
import SidebarNavigation from "../../components/common/sidebarnavigation.component";
import LoginPage from "./loginPage";
import CreatePage from "./createUser";

export default function ViewInvite() {
    const stopBouncing = () => {
        const el = document.getElementById("promo-icon");
        if (el) {
            el.classList.remove("animate-bounce");
        }
    }
    let params = useParams();
    const venueID = params.guid;
    const data = useLoaderData();
    console.log(data);
    const [teamList, setTeamList] = useState([])
    let [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [statusMessage, setStatusMessage] = useState(null);
    const [detailStatusMessage, setDetailStatusMessage] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    useEffect(() => {
        setTimeout(() => stopBouncing(), 3500)
    }, []);



    const verifyInvite = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/p/user/verifyinvite.do`, {
            instance: 'promogogo', ticket: localStorage.getItem('ticket'), token: params.token
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log(response);


        }).catch(function (error) {
            console.log(error);
            setStatusMessage(error.message)
            setDetailStatusMessage(error.response.data.error)
        });

    }

    const verifyUser = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/p/user/sendemailverification.do`, {
            instance: 'promogogo',
            ticket: localStorage.getItem('ticket'),
            server: "https://mobilitus.is"
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log('verifyUser',response);
            window.location.href = '/manage/user';

        });
    }
    const submitForm = (e) => {
        e.preventDefault();
        setStatusMessage(null);
        setDetailStatusMessage(null)
        axios.post(process.env.REACT_APP_API_URL + '/login/app/createuser.do', document.querySelector('#createform'),{
            headers: {
                'Content-Type': 'application/json'
            }})
                .then(function (response) {
                    console.log(response);
                    if (response.data.ticket)
                    {
                        localStorage.setItem('ticket', response.data.ticket);
                        localStorage.setItem('username', response.data.name);
                        verifyInvite();
                        verifyUser();

                        //window.location.href = '/dashboard';
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setStatusMessage(error.message)
                    setDetailStatusMessage(error.response.data.error)
                });
    };
    const submitLoginForm = (e) => {
        e.preventDefault();
        setStatusMessage(null);
        setDetailStatusMessage(null)
        axios.post(`${process.env.REACT_APP_API_URL}/login/app/login.do`, document.querySelector('#loginform'), {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }).then(function (response) {
                    console.log(response);
                    if (response.data.ticket)
                    {
                        localStorage.setItem('ticket', response.data.ticket);
                        localStorage.setItem('username', response.data.name);
                        verifyInvite().then(function (response) {
                            window.location.href = '/dashboard';
                        }).catch(function (error) {
                            console.log('catch', error);
                        });
                        //window.location.href = '/dashboard';
                    }

                }).catch(function (error) {
                    console.log(error);
                    setStatusMessage(error.message)
                    setDetailStatusMessage(error.response.data.error)
                });
        };

    return (

            <div className="min-h-full">

                <div className="bg-white ">
                    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-12 lg:max-w-3xl lg:px-8">
                        <div className="mt-2">
                            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                                <div className="px-4 py-5 sm:px-6">
                                    {/* Content goes here */}
                                    {/* We use less vertical padding on card headers on desktop than on body sections */}
                                    <h1 className="text-2xl tracking-tight text-gray-500">Team</h1>
                                </div>
                                <div className="px-4 py-5 sm:p-6">

                                    <div className="mx-auto mt-6 max-w-2xl leading-8">

                                        <div className="mt-2">
                                            <h1 className="text-2xl tracking-tight text-gray-500">{data.title}</h1>
                                        </div>
                                        <p className={'mt-2'}>
                                            {getAttribute(data,'invitedby')} invited you to join the {getAttribute(data,'inviteproperty')} team.
                                        </p>
                                        {!localStorage.getItem('ticket') && (<div className={'mt-2'}>
                                            {!showLogin && <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                                                        <div className="flex justify-center">
                                                            <img id="promo-icon"
                                                                 className="h-16 w-auto animate-bounce"
                                                                 src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                                                                 alt="Promogogo"/>
                                                        </div>
                                                        <div className="sm:mx-auto sm:w-full sm:max-w-sm ">

                                                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Create
                                                                an
                                                                account</h2>
                                                        </div>
                                                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded p-3">
                                                            <form id="createform" className="space-y-6"
                                                                  onSubmit={submitForm}>
                                                                <input type="hidden" name="instance" value="promogogo"/>
                                                                <input type="hidden" name="appName" value="mobilitus"/>

                                                                <div>
                                                                    <label htmlFor="name"
                                                                           className="block text-sm font-medium leading-6 text-gray-900">Your
                                                                        name</label>
                                                                    <div className="mt-2">
                                                                        <input id="name" name="name" type="text"
                                                                               autoComplete="name" required
                                                                               className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="loginName"
                                                                           className="block text-sm font-medium leading-6 text-gray-900">Email
                                                                        address</label>
                                                                    <div className="mt-2">
                                                                        <input id="loginName" name="loginName"
                                                                               type="email" autoComplete="email"
                                                                               required
                                                                               className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="flex items-center justify-between">
                                                                        <label htmlFor="password"
                                                                               className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <input id="password" name="password"
                                                                               type="password"
                                                                               autoComplete="current-password"
                                                                               required
                                                                               className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <button type="submit"
                                                                            className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Create
                                                                        account
                                                                    </button>
                                                                </div>
                                                                <div className="text-center text-red-500 p-1">
                                                                    {statusMessage &&
                                                                     <span className="text-center text-red-500 p-1">{statusMessage}</span>}
                                                                    {detailStatusMessage &&
                                                                     <span className="text-center text-red-500 p-1"><br/>{detailStatusMessage}</span>}
                                                                </div>

                                                            </form>

                                                            <p className="mt-10 text-center text-sm text-gray-500">
                                                                Already a member? <div onClick={() => {setShowLogin(true)}}
                                                                                     className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Log
                                                                in</div>
                                                            </p>
                                                        </div>
                                                    </div> }
                                            {showLogin &&
                                             <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded p-3">
                                                 <form id="loginform" className="space-y-6" onSubmit={submitLoginForm}>
                                                     <input type="hidden" name="instance" value="promogogo"/>
                                                     <input type="hidden" name="appName" value="backstage"/>

                                                     <div>
                                                         <label htmlFor="loginName"
                                                                className="block text-sm font-medium leading-6 text-gray-900">Email
                                                             address</label>
                                                         <div className="mt-2">
                                                             <input id="loginName" name="loginName" type="email"
                                                                    autoComplete="email" required
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                                         </div>
                                                     </div>

                                                     <div>
                                                         <div className="flex items-center justify-between">
                                                             <label htmlFor="password"
                                                                    className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                                             <div className="text-sm">
                                                                 <a href="/sendresetlink"
                                                                    className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot
                                                                     password?</a>
                                                             </div>
                                                         </div>
                                                         <div className="mt-2">
                                                             <input id="password" name="password" type="password"
                                                                    autoComplete="current-password"
                                                                    required
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                                         </div>
                                                     </div>

                                                     <div>
                                                         <button type="submit"
                                                                 className="flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign
                                                             in
                                                         </button>
                                                     </div>
                                                     <div className="text-center text-red-500 p-1">
                                                         {statusMessage &&
                                                          <span className="text-center text-red-500 p-1">{statusMessage}</span>}
                                                         {detailStatusMessage &&
                                                          <span className="text-center text-red-500 p-1"><br/>{detailStatusMessage}</span>}
                                                     </div>
                                                 </form>

                                                 <p className="mt-10 text-center text-sm text-gray-500">
                                                     Not a member? <div onClick={() => {setShowLogin(false)}}
                                                                      className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Create
                                                     your account</div>
                                                 </p>
                                             </div>}


                                        </div>)}
                                        {localStorage.getItem('ticket') && (<div>
                                            <button className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}
                                                    onClick={verifyInvite}>Accept Invite
                                            </button>
                                        </div>)}


                                    </div>

                                    <div className="mt-2">


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-4 lg:max-w-3xl lg:px-8">

                    </div>
                </div>
            </div>)
}
