import React, {useEffect} from "react";

const navigation = {
    main: [
        { name: 'Auglýsingar', href: '/admarket' },
        { name: 'Menningarumfjöllun', href: '/media' },
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/mobilitus',
            icon: (props: any) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        }
    ],
}

export const Header = () => {
    const stopBouncing = () => {
        const el = document.getElementById("promo-icon");
        if (el) {
            el.classList.remove("animate-bounce");
        }
    }
    useEffect(() => {
        setTimeout(() => stopBouncing(), 3500)
        //getPhotos();

    }, []);
    return (<div className="mx-auto max-w-2xl text-center mt-2">
        <div className="flex justify-center">
            <a href={'/'}><img id="promo-icon"
                 className="h-16 w-auto animate-bounce"
                 src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                               alt="Promogogo"/> </a>
        </div>
    </div>)

}

export const Footer = () => {

       const now = new Date().getFullYear();


        return (
            <div className="bg-white">
                <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
                    <nav aria-label="Footer" className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
                        {navigation.main.map((item) => (<div key={item.name} className="pb-6">
                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                    {item.name}
                                </a>
                            </div>))}
                    </nav>
                    <div className="mt-10 flex justify-center space-x-10">
                        {navigation.social.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">{item.name}</span>
                                <item.icon aria-hidden="true" className="h-6 w-6"/>
                            </a>))}
                    </div>
                    <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                        &copy; {now} Mobilitus, Inc. All rights reserved.
                    </p>
                    <p className="mx-auto mt-10 text-left text-xs leading-5 text-gray-500 grid grid-cols-2 md:w-1/2">
                       <div className={'col-span-1'}><img className={'h-20'} src={'https://media.promogogo.com/gjugg/2023-04-27/53fe8b84a6c34b6bb6e1859b1e0c6b86.jpg'} alt={'Tækniþróunarsjóður'}/></div>
                        <div className={'col-span-1'}>Mobilitus ehf. vinnur nú að verkefninu Víðmiðlun vélgreindra viðburðaupplýsinga, sem fékk Vaxtarstyrk frá Tækniþróunarsjóði fyrir árin 2023-2024. Gjugg byggir á þeim grunni.</div>
                    </p>
                </div>
            </div>)


}
